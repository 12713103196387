import React from 'react';
import { graphql, Link } from 'gatsby';
import { Breadcrumb, Typography, Card, Tag, Image } from 'antd';
import { MDXRenderer } from 'gatsby-plugin-mdx';
//@ts-ignore
import { MDXProvider } from '@mdx-js/react';
import { Category } from '../types/Category';
import { MdxArticle } from '../types/Article';
import { SiteMetadata } from '../types/SiteMetadata';
import ArticleList from '../components/ArticleList';
import { SEO } from '../components/SEO';
const { Paragraph } = Typography;

type PageQueryData = {
  mdx: MdxArticle;
  allCategory: { nodes: Category[] };
  site: {
    siteMetadata: SiteMetadata;
  };
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
};

export const pageQuery = graphql`
  query ($articleId: String) {
    mdx(id: { eq: $articleId }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        category
        description
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allCategory {
      nodes {
        name
        slug
        url
      }
    }
    allMdx {
      edges {
        node {
          id
          frontmatter {
            title
            description
            category
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const stripSlug = (slug: string) => {
  const prefix = '/articles/';
  return slug
    .substr(prefix.length)
    .substring(0, slug.length - prefix.length - 1);
};

const mdxComponents = {
  // a: A,
  p: (props: any) => <Paragraph {...props} />,
  // blockquote: Blockquote,
  // ol: Ol,
  // ul: Ul,
  // h1: H1,
  // h2: H2,
  // h3: H3,
  // code: Code,
  img: (props: any) => <Image {...props} />
};

export default function ({ data }: { data: PageQueryData }) {
  const {
    mdx,
    site: { siteMetadata },
    allMdx
  } = data;
  const { frontmatter, fields } = mdx;
  const { title, description } = frontmatter;
  const categories = data.allCategory.nodes.filter(
    (c) => mdx.frontmatter.category === c.slug
  );

  return (
    <>
      <SEO
        title={title}
        description={description}
        pathname={fields.slug}
        siteUrl={siteMetadata.siteUrl}
      />
      <Card>
        <Breadcrumb aria-label="Navigation">
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {typeof categories != 'undefined' && categories[0] && (
              <Link key={categories[0].url} to={categories[0].url}>
                {categories[0].name}
              </Link>
            )}
          </Breadcrumb.Item>

          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <MDXProvider components={mdxComponents}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Card>
    </>
  );
}
